import React, { useState, useEffect } from 'react';
import './listView.css';
import axios from 'axios';
import { debounce } from 'lodash';
import logo from '../assets/Logo_black.svg';
import Popup from "../components/popups/confirmationPopup";

const API_URL = `/ResQ`;

function ListView() {
    const [categories, setCategories] = useState([]);
    const [entries, setEntries] = useState([]);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]); // Multiple categories
    const [newEntry, setNewEntry] = useState({ name: '', tags: '',minimumAmount: 1 , amount: 1 });
    const [newCategory, setNewCategory] = useState('');
    const [amountChanges, setAmountChanges] = useState({});
    const [errors, setErrors] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [editEntryId, setEditEntryId] = useState(null);
    const [editEntryName, setEditEntryName] = useState('');
    const [showEditEntryModal, setShowEditEntryModal] = useState(false);

    const [editEntryMinimumAmount, setEditEntryMinimumAmount] = useState('');
    const [showEditMinimumAmountModal, setShowEditMinimumAmountModal] = useState(false);

    const [newCategoryName, setNewCategoryName] = useState(''); // For editing category name

    const [amountPopupIsOpen, setAmountPopupIsOpen] = useState(false);
    const [removePopupIsOpen, setRemovePopupIsOpen] = useState(false);
    const [popupData, setPopupData] = useState({});

    const userId = JSON.parse(sessionStorage.getItem('user'))?.userId;
    const token = sessionStorage.getItem('token');  // Ensure token is fetched
    const userRole = sessionStorage.getItem('role');
    useEffect(() => {
        if (!userId) {
            window.location.href = '/login';
            return;
        }
        fetchCategories();
        fetchEntries();  // Fetch all entries by default on page load
    }, []);

    useEffect(() => {
        debouncedFetchEntries();
        return () => debouncedFetchEntries.cancel();
    }, [selectedCategoryIds, searchTerm]);

    const debouncedFetchEntries = debounce(() => {
        fetchEntries();
    }, 300);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${API_URL}/categories`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchEntries = async () => {
        try {
            // Wende sowohl Suchbegriff als auch Kategorien an, falls sie vorhanden sind
            const params = {
                categoryIds: selectedCategoryIds.length > 0 ? selectedCategoryIds.join(',') : undefined,
                searchTerm: searchTerm || undefined
            };

            const response = await axios.get(`${API_URL}/entries`, {
                params,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setEntries(response.data);
        } catch (error) {
            console.error('Error fetching entries:', error);
        }
    };

    const searchEntries = async () => {
        try {
            const response = await axios.get(`${API_URL}/entries/search`, {
                params: {
                    searchTerm,
                    categoryIds: selectedCategoryIds.join(',') || undefined,
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setEntries(response.data);
        } catch (error) {
            console.error('Error searching entries:', error);
        }
    };

    const handleCategoryToggle = (categoryId) => {
        setErrors({});
        setSelectedCategoryIds(prevSelected => {
            if (prevSelected.includes(categoryId)) {
                return prevSelected.filter(id => id !== categoryId);
            } else {
                return [...prevSelected, categoryId];
            }
        });
    };

    const handleShowAllEntries = () => {
        setErrors({});
        setSearchTerm('');
        setSelectedCategoryIds([]);  // Clear all selected categories
        fetchEntries();  // Fetch all entries
    };

    const handleSearchChange = (e) => {
        const trimmedValue = e.target.value.trimStart(); // Entfernt führende Leerzeichen
        setSearchTerm(trimmedValue);
    };

    const handleAddCategory = async () => {
        const trimmedCategory = newCategory.trim(); // Entfernt führende und nachgestellte Leerzeichen

        if (!trimmedCategory) {
            console.error('Category name cannot be empty.');
            return;
        }
        try {
            await axios.post(`${API_URL}/categories`,
                { name: trimmedCategory, code: trimmedCategory },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            setNewCategory('');
            setErrors({ ...errors, categoryAdd: '' });
            fetchCategories();
            fetchEntries();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrors({ ...errors, categoryAdd: error.response.data.error });
            } else {
                setErrors({ ...errors, categoryAdd: 'Error adding category, it might already exist.' });
            }
        }
    };

    const handleAddEntry = async () => {
        const trimmedName = newEntry.name.trim();
        const trimmedTags = newEntry.tags.trim();
        newEntry.name = trimmedName;
        newEntry.tags = trimmedTags;
        if (!trimmedName || !trimmedTags || newEntry.amount <= 0 || newEntry.minimumAmount <= 0 || selectedCategoryIds.length === 0) {
            console.error('Invalid entry input.');
            return;
        }

        try {
            // Handle adding entry to multiple categories with MasterEntry
            if (selectedCategoryIds.length > 1) {
                await axios.post(`${API_URL}/entries`, {
                    ...newEntry,
                    categoryIds: selectedCategoryIds
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            }
            // Handle adding entry to a single category
            else if (selectedCategoryIds.length === 1) {
                await axios.post(`${API_URL}/entries`, {
                    ...newEntry,
                    categoryIds: selectedCategoryIds
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            }

            setNewEntry({ name: '', tags: '', minimumAmount: 1, amount: 1 });
            setErrors({ ...errors, entryAdd: '' });
            fetchEntries(); // Refresh the entries after adding
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrors({ ...errors, entryAdd: error.response.data.error });
            } else {
                setErrors({ ...errors, entryAdd: 'Error adding entry, it might already exist.' });
            }
        }
    };



    const handleRemoveEntry = async () => {
        const { entryId } = popupData;
        try {
            await axios.delete(`${API_URL}/entries/delete/${entryId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            fetchEntries();
            setRemovePopupIsOpen(false);
        } catch (error) {
            setErrors({ ...errors, [entryId]: 'Error deleting entry.' });
            setRemovePopupIsOpen(false);
        }
    };

    const handleUpdateAmount = async () => {
        const { entryId, add, value } = popupData;

        try {
            const entry = entries.find(entry => entry._id === entryId);
            const previousAmount = entry.amount;
            const newAmount = add ? entry.amount + value : entry.amount - value;

            if (!add && entry.amount - value < 0) {
                setErrors({ ...errors, [entryId]: 'Cannot reduce amount below 0. Refill first or contact the responsible person!' });
                setAmountPopupIsOpen(false);
                return;
            }

            await axios.put(`${API_URL}/entries/update/${entryId}`, { value, add },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

            try {
                // Loggen des Updates
                await axios.post(`${API_URL}/logs`, {
                    entryId,
                    entryName: entry.name,
                    previousAmount,
                    newAmount
                }, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            }catch (error){
                setErrors({ ...errors, [entryId]: 'Error writing to log file.' });
                setAmountPopupIsOpen(false);
            }

            setErrors({ ...errors, [entryId]: '' });  // Clear the error on success
            fetchEntries();

            // Reset the amountChanges for this entryId after the update
            setAmountChanges({ ...amountChanges, [entryId]: 1 });
            setAmountPopupIsOpen(false);
        } catch (error) {
            setErrors({ ...errors, [entryId]: 'Error updating entry amount.' });
            setAmountPopupIsOpen(false);
        }
    };

    const handleAmountChange = (entryId, value) => {
        // Set the amount to the current value or clear it if empty
        if (value === '' || value < 0) {
            setAmountChanges({ ...amountChanges, [entryId]: 0 });
        } else {
            setAmountChanges({ ...amountChanges, [entryId]: Number(value) });
        }
    };

    const handleBlur = (entryId) => {
        // If the input is empty, reset it to 1
        if (!amountChanges[entryId]) {
            setAmountChanges({ ...amountChanges, [entryId]: 1 });
        }
    };

    const handleLogout = () => {
        sessionStorage.clear();
        window.location.href = '/login';
    };

    const handleShowEditEntryModal = (entryId, entryName) => {
        setEditEntryId(entryId);
        setEditEntryName(entryName);
        setShowEditMinimumAmountModal(false);
        setShowEditEntryModal(true);
    };

    const handleShowEditMinimumAmountModal = (entryId, entryMinimumAmount) => {
        setEditEntryId(entryId);
        setEditEntryMinimumAmount(entryMinimumAmount);
        setShowEditEntryModal(false);
        setShowEditMinimumAmountModal(true);
    };


    const handleEditEntrySubmit = async () => {

        const trimmedEntryName = editEntryName.trim();

        if (!trimmedEntryName) {
            setErrors({ ...errors, [editEntryId]: 'Entry name cannot be empty.' });
            return;
        }

        try {
            await axios.put(`${API_URL}/entries/edit-naming/${editEntryId}`,
                { name: trimmedEntryName },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            setShowEditEntryModal(false);
            setErrors({ ...errors, [editEntryId]: '' });
            setEditEntryId(null);
            fetchEntries();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrors({ ...errors, [editEntryId]: error.response.data.error });
            } else {
                setErrors({ ...errors, [editEntryId]: 'Error updating entry, this name might already exist.' });
            }
        }
    };

    const handleEditMinimumAmountSubmit = async () => {
        const trimmedMinimumAmount = parseInt(editEntryMinimumAmount, 10);
        if (isNaN(trimmedMinimumAmount) || trimmedMinimumAmount <= 0) {
            setErrors({ ...errors, [editEntryId]: 'Minimum stock must be a positive number.' });
            return;
        }

        try {
            await axios.put(`${API_URL}/entries/edit-minimum/${editEntryId}`,
                { minimumAmount: trimmedMinimumAmount },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            setShowEditMinimumAmountModal(false);
            setErrors({ ...errors, [editEntryId]: '' });
            setEditEntryId(null);
            fetchEntries();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrors({ ...errors, [editEntryId]: error.response.data.error });
            } else {
                setErrors({ ...errors, [editEntryId]: 'Error updating minimum stock.' });
            }
        }
    };


    const handleCategoryNameChange = async () => {
        if (selectedCategoryIds.length === 1) {
            const categoryId = selectedCategoryIds[0];
            const trimmedCategoryName = newCategoryName.trim();

            if (!trimmedCategoryName) {
                setErrors({ ...errors, category: 'Category name cannot be empty.' });
                return;
            }
            try {
                await axios.put(`${API_URL}/categories/${categoryId}`,
                    { name: trimmedCategoryName },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                setNewCategoryName('');
                setErrors({ ...errors, category: '' });
                fetchCategories();
                fetchEntries();
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error) {
                    setErrors({ ...errors, category: error.response.data.error });
                } else {
                    setErrors({ ...errors, category: 'Error while editing name, this name might already exist.' });
                }
            }
        }
    };

    const clearSearchTerm = () => {
        setSearchTerm('');
        fetchEntries();
    };

    const handleAdminNavigation = () => {
        window.location.href = '/adminView';
    };

    const openRemovePopup = (entryId) => {
        setPopupData({ entryId, actionText: "Are you sure you want to remove this item? You cannot undo this." });
        setRemovePopupIsOpen(true);
        setAmountPopupIsOpen(false);
    };

    const openAmountPopup = (entryId, add, value) => {
        const actionText = add
            ? `Are you sure you want to add ${value}`
            : `Are you sure you want to subtract ${value}`;
        setPopupData({ entryId, add, actionText, value });
        setAmountPopupIsOpen(true);
        setRemovePopupIsOpen(false);
    };


    return (
        <div className="ListViewContainer">
            <header>
                <img src={logo} alt="Logo" className="site-logo" />
                <div className="header-buttons">
                    {userRole === 'admin' && (
                        <button className="button button-normal" onClick={handleAdminNavigation}>
                            Admin Panel
                        </button>
                    )}
                    <button className="button LogoutButton" onClick={handleLogout}>Logout</button>
                </div>
            </header>
            <div className="ListView">
                <h2>Storage management tool</h2>

                {/* Search Bar */}
                <div className="search-section">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                        className="search-input"
                    />
                    {searchTerm && (
                        <button className="clear-search-button" onClick={clearSearchTerm}>X</button>
                    )}
                </div>

                <div className="FilterSection">
                    <div className="category-buttons">
                        <button
                            className={`button ${selectedCategoryIds.length === 0 ? 'button-active' : 'button-inactive'}`}
                            onClick={handleShowAllEntries}
                        >
                            Show All
                        </button>
                        {categories.map((category) => (
                            <button
                                key={category._id}
                                className={`button ${selectedCategoryIds.includes(category._id) ? 'button-active' : 'button-inactive'}`}
                                onClick={() => handleCategoryToggle(category._id)}
                            >
                                {category.name}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Show category name edit input if exactly one category is selected */}
                {(userRole === 'admin' && selectedCategoryIds.length === 1) && (
                    <div className="CategorySections">
                        {errors.category && <div className="error-message">{errors.category}</div>}
                        <input
                            type="text"
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                            placeholder="Edit category name of currently selected category"
                        />
                        <button className="button button-normal" onClick={handleCategoryNameChange}>Save Category Name</button>
                    </div>
                )}

                {/* Category Section to add new categories */}
                {userRole === 'admin' && (
                    <div className="CategorySections">
                        {errors.categoryAdd && <div className="error-message">{errors.categoryAdd}</div>}
                        <input
                            type="text"
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                            placeholder="Enter new category"
                        />
                        <button className="button button-normal" onClick={handleAddCategory}>Add Category</button>
                    </div>
                )}

                {/* Only show add entry section if at least one category is selected */}
                {(userRole === 'admin' && selectedCategoryIds.length > 0) && (
                    <div className="AddEntrySection">
                        <h4>Add Entry to selected categorie(s)</h4>
                        {errors.entryAdd && <div className="error-message">{errors.entryAdd}</div>}

                        {/* Container für das erste Textfeld und die beiden Number-Inputs */}
                        <div className="EntryRow">
                            <div className="input-group">
                                <label htmlFor="productname">Product name:</label>
                                <input
                                    id="productname"
                                    type="text"
                                    value={newEntry.name}
                                    onChange={(e) => setNewEntry({ ...newEntry, name: e.target.value })}
                                    placeholder="Enter new product name"
                                />
                            </div>
                            {/* Text und Eingabefeld für den Mindestbestand */}
                            <div className="input-group">
                                <label htmlFor="minimumAmount">Minimum stock:</label>
                                <input
                                    id="minimumAmount"
                                    type="number"
                                    value={newEntry.minimumAmount}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            setNewEntry({ ...newEntry, minimumAmount: Number(value) });
                                        }
                                    }}
                                    placeholder="Minimum amount for stock"
                                />
                            </div>

                            {/* Text und Eingabefeld für die Bestandsmenge */}
                            <div className="input-group">
                                <label htmlFor="amount">Current stock:</label>
                                <input
                                    id="amount"
                                    type="number"
                                    value={newEntry.amount}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            setNewEntry({ ...newEntry, amount: Number(value) });
                                        }
                                    }}
                                    placeholder="Amount"
                                />
                            </div>
                        </div>

                        {/* Zweite Zeile mit dem Keywords/Synonyms-Textfeld */}
                        <div className="CategorySections">
                            <input
                                type="text"
                                value={newEntry.tags}
                                onChange={(e) => setNewEntry({ ...newEntry, tags: e.target.value })}
                                placeholder="Keywords or synonyms (comma separated like: intubation,ventilation,...)"
                            />

                            {/* Button zum Hinzufügen des Eintrags */}
                            <button className="button button-normal" onClick={handleAddEntry}>Add Entry</button>
                        </div>
                    </div>
                )}

                {/* Show entries */}
                {entries.length > 0 && (
                    <div className="EntrySection">
                        <h3>{selectedCategoryIds.length > 0 ? 'Filtered Entries' : 'All Entries'}</h3>
                        <ul>
                            {entries.map((entry) => (
                                <li key={entry._id}>
                                    <div className="entry-info">
                                        <div className="entry-field">
                                            <span className="entry-text-info">Product Name</span>
                                            <span className="entry-text">{entry.name}</span>
                                        </div>
                                        <div className="entry-field">
                                            <span className="entry-text-info">Stock Left</span>
                                            <span className="entry-text">{entry.amount}</span>
                                        </div>
                                        <div className="entry-field">
                                            <span className="entry-text-info">Storage Code</span>
                                            <span className="entry-text">{entry.code}</span>
                                        </div>
                                        {userRole === 'admin' && (
                                            <div className="entry-field">
                                                <span className="entry-text-info">Minimum stock</span>
                                                <span className="entry-text">{entry.minimumAmount}</span>
                                            </div>
                                        )}
                                    </div>

                                    {/* Error message for this entry */}
                                    {errors[entry._id] && (
                                        <div className="error-message">{errors[entry._id]}</div>
                                    )}

                                    <div className="entry-actions">
                                        <input
                                            type="number"
                                            value={amountChanges[entry._id] || 1}
                                            onChange={(e) => handleAmountChange(entry._id, e.target.value)}
                                            onFocus={(e) => e.target.select()}  // Selects the text on focus (optional)
                                            onBlur={(e) => handleBlur(entry._id)}  // Ensures 1 is set if the field is left empty
                                            placeholder="Amount to change"
                                            className="amount-input"
                                        />
                                        <div className="action-buttons">
                                            <button
                                                className="button subtract-button"
                                                onClick={() => openAmountPopup(entry._id, false, amountChanges[entry._id] || 1)}
                                            >
                                                Subtract
                                            </button>
                                            <button
                                                className="button add-button"
                                                onClick={() => openAmountPopup(entry._id, true, amountChanges[entry._id] || 1)}
                                            >
                                                Add
                                            </button>
                                            {userRole === 'admin' && (
                                                <button className="button remove-button" onClick={() => openRemovePopup(entry._id)}>
                                                    Remove Entry
                                                </button>
                                            )}
                                            {userRole === 'admin' && (
                                                <button className="button button-normal" onClick={() => handleShowEditEntryModal(entry._id, entry.name)}>
                                                    Edit product name
                                                </button>
                                            )}
                                            {userRole === 'admin' && (
                                                <button className="button button-normal" onClick={() => handleShowEditMinimumAmountModal(entry._id, entry.minimumAmount)}>
                                                    Edit minimum stock
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    {amountPopupIsOpen && (
                                        <Popup
                                            actionText={popupData.actionText}
                                            onConfirm={handleUpdateAmount}
                                            onCancel={() => setAmountPopupIsOpen(false)}
                                        >
                                        </Popup>
                                    )}

                                    {removePopupIsOpen && (
                                        <Popup
                                            actionText={popupData.actionText}
                                            onConfirm={handleRemoveEntry}
                                            onCancel={() => setRemovePopupIsOpen(false)}
                                        >
                                        </Popup>
                                    )}

                                    {/* Inline Edit Entry Form */}
                                    {(editEntryId === entry._id && showEditEntryModal) && (
                                        <div className="edit-entry-form">
                                            <input
                                                type="text"
                                                value={editEntryName}
                                                onChange={(e) => setEditEntryName(e.target.value)}
                                                placeholder="New entry name"
                                            />
                                            <div className="action-buttons">
                                                <button className="button button-normal" onClick={
                                                    handleEditEntrySubmit
                                                }>Save</button>
                                                <button className="button button-normal" onClick={() => {
                                                    setShowEditEntryModal(false)
                                                    setEditEntryId(null);
                                                }}>Cancel</button>
                                            </div>
                                        </div>
                                    )}

                                    {(editEntryId === entry._id && showEditMinimumAmountModal) && (
                                        <div className="edit-entry-form">
                                            <input
                                                type="number"
                                                value={editEntryMinimumAmount}
                                                onChange={(e) => setEditEntryMinimumAmount(e.target.value)}
                                                placeholder="New minimum stock amount"
                                            />
                                            <div className="action-buttons">
                                                <button className="button button-normal" onClick={
                                                    handleEditMinimumAmountSubmit
                                                }>Save</button>
                                                <button className="button button-normal" onClick={() => setShowEditMinimumAmountModal(false)}>Cancel</button>
                                            </div>
                                        </div>
                                    )}

                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div className="creator">
                <p>© 2024 - Created by Simon Jell, assisted by Luis Digel</p>
            </div>
        </div>
    );
}

export default ListView;
