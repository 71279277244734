import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './adminView.css';
import './listView.css';
import logo from "../assets/Logo_black.svg";
import axios from 'axios';
import * as XLSX from 'xlsx';

const API_URL = `/ResQ`;

function AdminView() {
    const navigate = useNavigate();
    const userRole = sessionStorage.getItem('role');
    const token = sessionStorage.getItem('token');
    const [lowStockItems, setLowStockItems] = useState([]);
    const [loggedItems, setLogItems] = useState([]);
    const [logPage, setLogPage] = useState(1);
    const [hasMoreLogs, setHasMoreLogs] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const init = async () => {
            if (userRole !== 'admin') {
                navigate('/');
            } else {
                await fetchLowStockItems();
                await fetchLogs(logPage);
            }
        };
        init();
    }, [userRole, navigate]);

    const fetchLowStockItems = async () => {
        try {
            const response = await axios.get(`${API_URL}/entries`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const itemsBelowMinimum = response.data.filter(item =>
                item.minimumAmount && item.amount < item.minimumAmount
            );

            setLowStockItems(itemsBelowMinimum);
        } catch (error) {
            console.error('Error fetching low stock items:', error);
        }
    };

    const fetchLogs = async (page) => {
        try {
            const response = await axios.get(`${API_URL}/logs`, {
                params: { page },
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.length === 0) {
                setHasMoreLogs(false);
                setErrors({ ...errors, logs: 'No older logs available.' });
            } else {
                setLogItems(prevItems => [...prevItems, ...response.data]);
                setErrors({ ...errors, logs: '' });
            }
        } catch (error) {
            setErrors({ ...errors, logs: 'Failed to fetch logs. Please try again later.' });
        }
    };

    const loadMoreLogs = async () => {
        if (hasMoreLogs) {
            const nextPage = logPage + 1;
            setLogPage(nextPage);
            await fetchLogs(nextPage);
        }
    };

    const downloadExcel = async () => {
        try {
            const response = await axios.get(`${API_URL}/export/excel`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                // Aktuelles Datum im Format YYYY-MM-DD
                const currentDate = new Date().toISOString().split('T')[0];
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `stock-export_${currentDate}.xlsx`; // Verwende den gewünschten Dateinamen
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {

            }
        } catch (error) {

        }
    };

    const downloadLogExcel = async () => {
        try {
            const response = await axios.get(`${API_URL}/export/logs`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200 && response.data.length > 0) {
                const headers = ["Product Name", "Previous Amount", "New Amount", "Timestamp"];

                // Datenstruktur für das Excel-Dokument vorbereiten
                const data = response.data.map(log => ({
                    "Product Name": log.entryName,
                    "Previous Amount": log.previousAmount,
                    "New Amount": log.newAmount,
                    "Timestamp": new Date(log.timestamp).toLocaleString()
                }));

                // SheetJS-Arbeitsblatt erstellen
                const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Logs");

                // Excel-Datei zum Herunterladen vorbereiten
                const currentDate = new Date().toISOString().split('T')[0];
                XLSX.writeFile(workbook, `logs-export_${currentDate}.xlsx`);
            } else {

            }
        } catch (error) {

        }
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };

    const handleListNavigation = () => {
        window.location.href = '/listView';
    };

    return (
        <div className="ListViewContainer">
            <header>
                <img src={logo} alt="Logo" className="site-logo" />
                <div className="header-buttons">
                    {userRole === 'admin' && (
                        <button className="button button-normal" onClick={handleListNavigation}>
                            User Panel
                        </button>
                    )}
                    <button className="button LogoutButton" onClick={handleLogout}>Logout</button>
                </div>
            </header>
            <div className="ListView">
                <h2>Admin tools</h2>
                <div className="ButtonPanel">
                    <button className="button button-normal" onClick={downloadExcel}>
                        Export storage list to Excel
                    </button>
                    <button className="button button-normal" onClick={downloadLogExcel}>
                        Export logs to Excel
                    </button>
                </div>
                <div className="Panel">
                    <h2>Items below minimum stock</h2>
                    <div className="Panel_content">
                        {lowStockItems.length > 0 ? (
                            <ul>
                                {lowStockItems.map(item => (
                                    <li key={item._id} className="Panel_content_li">
                                        <div className="entry-info">
                                            <div className="entry-field">
                                                <span className="entry-text-info">Product Name</span>
                                                <span className="entry-text">{item.name}</span>
                                            </div>
                                            <div className="entry-field">
                                                <span className="entry-text-info">Stock Left</span>
                                                <span className="entry-text">{item.amount}</span>
                                            </div>
                                            <div className="entry-field">
                                                <span className="entry-text-info">Minimum stock</span>
                                                <span className="entry-text">{item.minimumAmount}</span>
                                            </div>
                                            <div className="entry-field">
                                                <span className="entry-text-info">Storage Code</span>
                                                <span className="entry-text">{item.code}</span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>All items are sufficiently stocked.</p>
                        )}
                    </div>
                </div>

                <div className="Panel">
                    <h2>Item logs</h2>
                    <div className="Panel_content Panel_content_logs">
                        {loggedItems.length > 0 ? (
                            <ul>
                                {loggedItems.map(item => (
                                    <li key={item._id} className="Panel_content_li">
                                        <div className="entry-info">
                                            <div className="entry-field">
                                                <span className="entry-text-info">Product Name</span>
                                                <span className="entry-text">{item.entryName}</span>
                                            </div>
                                            <div className="entry-field">
                                                <span className="entry-text-info">Previous amount</span>
                                                <span className="entry-text">{item.previousAmount}</span>
                                            </div>
                                            <div className="entry-field">
                                                <span className="entry-text-info">New amount</span>
                                                <span className="entry-text">{item.newAmount}</span>
                                            </div>
                                            <div className="entry-field">
                                                <span className="entry-text-info">Time</span>
                                                <span className="entry-text">{new Date(item.timestamp).toLocaleString()}</span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No logs available.</p>
                        )}
                        {errors.logs && (
                            <div className="error-message">{errors.logs}</div>
                        )}
                        <button className="button button-normal" onClick={loadMoreLogs}>
                            Load More Logs
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AdminView;
