import React from 'react';
import './popup.css';

function Popup({ actionText, onConfirm, onCancel }) {
    return (
        <div className="popup">
            <div className="popup-content">
                <p>
                    {actionText}
                </p>
                <div className="popup-buttons">
                    <button className="button cancel" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="button confirm" onClick={onConfirm}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Popup;
