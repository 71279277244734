import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './loginRegsiter.css';

const API_URL = `/ResQ`;

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // Fetches the user ID using the token
    const fetchUserId = async (username) => {
        const token = sessionStorage.getItem('token'); // Token from sessionStorage

        try {
            const response = await fetch(`${API_URL}/users/getid/${username}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include token in Authorization header
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data._id;
        } catch (error) {
            return null;
        }
    };

    // Handles form submission for login
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();

                // Store token in sessionStorage
                sessionStorage.setItem('token', data.token);

                // Fetch the user ID based on the username using the token
                const userId = await fetchUserId(username);

                if (userId) {
                    const tokenPayload = JSON.parse(atob(data.token.split('.')[1]));
                    const userRole = tokenPayload.role;
                    // Store user ID in sessionStorage and navigate to listView page
                    sessionStorage.setItem('user', JSON.stringify({ userId }));
                    sessionStorage.setItem('role', userRole);
                    navigate('/listView'); // Redirect to listView page
                } else {
                    alert('Failed to fetch user ID.');
                }
            } else {
                const errorData = await response.json();
                alert(`Login failed: ${errorData.error}`);
            }
        } catch (error) {
            alert('An error occurred during login. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="FormContainer">
            <h2>Login</h2>
            <div>
                <label>Username:</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Login</button>
        </form>
    );
}

export default Login;
