import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './userManagement/login';
import ListView from './pages/listView';
import AdminView from './pages/adminView';

function App() {
  return (
      <Router>
        <div className="App">
          <Routes>
              <Route path="/listView" element={<ListView />} />
              <Route path="/adminView" element={<AdminView />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;
